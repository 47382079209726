import React, { useState, useContext, useEffect } from "react";
import Button from "antd/lib/button";
import notification from "antd/lib/notification";
import ModalWizard from "./ModalWizard";
import { faLaptopMedical, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GlobalContext } from "views/App";
import * as actionName from "../../store/actions";
import { HandleError } from "utils.js/HandleError";

import { useHistory } from "react-router-dom";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { createAppointmentMD, meByAppointmentMD } from "./gqlWizard";
import { URLPATIENTCITAMD } from "../../chatCall/utils";

import get from "lodash/get";

const WizardMD = (_) => {
  const isOneClick = process.env.REACT_APP_ONE_CLICK_APPOINTMENT === "true";
  const { classNameIcon = "" } = _;
  const history = useHistory();
  const [visbleModal, setVisbleModal] = useState(false);
  const { state: globalState, dispatch: dispatchGlobal } = useContext(GlobalContext);
  const { user } = globalState;

  const [fetchAppointmentMD, { loading, error, data }] = useLazyQuery(meByAppointmentMD, {
    fetchPolicy: "no-cache",
  });
  const isMobile = () => window.matchMedia && window.matchMedia("(max-width: 700px)").matches;
  const [mutationAppointmentMD, { loading: appointmentLoading }] = useMutation(createAppointmentMD);
  useEffect(() => {
    if (data) {
      if (data.me.appointmentMD) {
        window.location = URLPATIENTCITAMD + data.me.appointmentMD.id;
      } else {
        dispatchGlobal({
          type: actionName.SET_USER,
          payload: {
            ...user,
            appointmentMD: data.me.appointmentMD,
          },
        });
        setVisbleModal(true);
      }
    }
    if (error) {
      notification.error({
        message: "Error",
        description: HandleError(error),
      });
    }
  }, [data, error, dispatchGlobal, history, user]);

  const redirectValidationWizard = () => {
    try {
      if (user) {
        if (user.appointmentMD) {
          fetchAppointmentMD();
        } else {
          setVisbleModal(true);
        }
      } else {
        setVisbleModal(true);
      }
    } catch (e) {
      console.log(e);
      setVisbleModal(false);
    }
  };

  if (user && get(user, "role.name") !== "patient") {
    return null;
  }
  const createOneClickAppointment = () => {
    try {
      if (user) {
        if (user.appointmentMD) {
          fetchAppointmentMD();
        } else {
          finishSteps();
        }
      } else {
        finishSteps();
      }
    } catch (e) {
      console.log(e);
      // setVisbleModal(false);
    }
  };
  const props = {
    Button: {
      loading: appointmentLoading || loading,
      disabled: appointmentLoading || loading,
      onClick: isOneClick ? createOneClickAppointment : redirectValidationWizard,
      icon: (
        <FontAwesomeIcon
          className={classNameIcon + " mx-2"}
          size='sm'
          icon={isMobile() ? faPhone : faLaptopMedical}
        />
      ),
      type: "default",
      shape: "round",
      size: "large",
      className: `button-success`,
    },
  };

  const finishSteps = async () => {
    const infoDataNewAppointmentMD = {
      appointmentSpecialty: "GENERAL",
      newSymptoms: "",
      reasonForConsultation: "",
      appointmentType: "CALL",
      patient: user.id,
      reasonForConsultation: "Preguntar al Paciente",
    };
    try {
      await mutationAppointmentMD({ variables: infoDataNewAppointmentMD });
      fetchAppointmentMD();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <ModalWizard user={user} visbleModal={visbleModal} onClose={() => setVisbleModal(false)} />
      {user ? (
        <Button {...props.Button}>
          <span className='break-words'>
            {user.appointmentMD ? "Ir a la sala de espera" : "Consulta Inmediata"}
          </span>
        </Button>
      ) : (
        <Button {...props.Button}>Consulta Inmediata</Button>
      )}
    </>
  );
};

export default WizardMD;
